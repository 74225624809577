import React from 'react'
import { Button } from 'react-bootstrap'
import {useNavigate} from "react-router-dom";

interface Props {
    message: string
    url?: string
    onClick?: () => void
}

const BooksitoutButton: React.FC<Props> = ({message, url, onClick}) => {

    const navigate = useNavigate()

    const onClickHandler = () => {
        if (url) {
            navigate(url)
            return
        }

        if (onClick) {
            onClick()
            return
        }
    }

    return (
        <Button variant={'book'} onClick={onClickHandler} style={{height: '40px', padding: '0px 50px'}}>
            {message}
        </Button>
    )
}
export default BooksitoutButton
