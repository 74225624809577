import {ContentBlock} from 'draft-js';

function TextFormBlockStyleFn(contentBlock: ContentBlock): string {
    const type = contentBlock.getType()
    if (type == 'list-item') {
        return 'unordered-list-item'
    }

    return ''
}

export default TextFormBlockStyleFn