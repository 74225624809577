import React from 'react'
import styled from 'styled-components';
import RowSpacer from "../common/styles/RowSpacer";
import RouteContainer from "../common/styles/RouteContainer";

const BooksitoutIntroductionRoute = () => {
    return (
        <RouteContainer>
            <RowSpacer/>

            <RouteTitle>책잇아웃이 추구하는 가치</RouteTitle>
            <RouteSubTitle>모든 제품을 만들 때 아래의 가치를 철저히 지킵니다.</RouteSubTitle>
            <RowSpacer/>

            <ValueTitle>1. 광고, 구독을 요구하지는 않고 1회성 결제만 요구합니다.</ValueTitle>
            <ValueDescription>
                광고는 싫어하지만 여러 서비스에 구독료를 내기는 부담스럽지 않으신가요?
                <br/>
                저희도 그렇습니다.
                <br/>
                책잇아웃의 제품은 1회성 결제로 영원히 제품을 이용할 수 있습니다.
            </ValueDescription>

            <RowSpacer/>

            <ValueTitle>2. 사용자가 편한 제품을 만들 수 있습니다.</ValueTitle>
            <ValueDescription>
                `상품의 대가를 치르지 않는다면 당신이 상품이다.`
                <br/>
                책잇아웃은 비용을 지불합니다.
                <br/>
                저희의 관심사는 사용자분들이 앱을 편하게 이용해 제 제품들에 대한 신뢰를 생기게 해 다른 제품도 구매하실 생각이 들게 하는 것입니다.
                <br/>
                불필요한 알림, 이메일, 푸시로 사용자를 괴롭힐 필요가 없습니다.
                <br/>
                광고를 노출시키기 위해 UI를 의도적으로 불편하게 설계할 필요가 없습니다.
            </ValueDescription>

            <RowSpacer/>

            <ValueTitle>3. 많은 제품을 내지 않지만, 낸 제품은 끝까지 책임집니다.</ValueTitle>
            <ValueDescription>
                사용자분들이 낸 돈을 쉽게 생각하지 않습니다.
                <br/>
                1만원이 안 되는 돈이지만, 책임감을 가지고 낸 제품은 계속 유지보수합니다.
            </ValueDescription>

            <RowSpacer/>
        </RouteContainer>
    )
}

const RouteTitle = styled.h2.attrs({
    className: 'text-start'
})`
`;

const RouteSubTitle = styled.h5.attrs({
    className: 'text-start text-secondary',
})``

const ValueTitle = styled.h2`
    font-size: 1.2em;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
`

const ValueDescription = styled.p`
    font-size: 1em;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: left;
`

export default BooksitoutIntroductionRoute
