import substrack from './substrack.jpeg'
import purrfitTracker from './purrfittracker.jpeg'
import pianoman from './pianoman.jpeg'
import featuredDays from './featureddays.jpeg'
import booksitout from './booksitout.jpeg'

const BooksitoutDemoImage = {
    substrack: substrack,
    purrfitTracker: purrfitTracker,
    pianoman: pianoman,
    booksitout: booksitout,
    featuredDays: featuredDays,
}

export default BooksitoutDemoImage