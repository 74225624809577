import React from 'react'
import { Card } from 'react-bootstrap'
import styled from 'styled-components';
import BooksitoutProductType from "../type/BooksitoutProductType";
import {BooksitoutCard, BooksitoutCardTitle} from '../BooksitoutCard';

interface Props {
    product: BooksitoutProductType
}

const ProductDetailDemoCard: React.FC<Props> = ({ product }) => {
	return (
		<BooksitoutCard>
			<Card.Body>
				<BooksitoutCardTitle>시연 이미지</BooksitoutCardTitle>

				<div className='row justify-content-center'>
						{product.demoImages.map((image) => {
                            return (
								<div className='col-12 col-md-8'>
									<Image src={image} alt='' className='rounded'/>
								</div>
							)
						})}
				</div>
			</Card.Body>
		</BooksitoutCard>
	)
}

const Image = styled.img.attrs({
	className: 'img-fluid',
})``

export default ProductDetailDemoCard