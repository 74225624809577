import React from 'react'
import styled from 'styled-components';
import { Card } from 'react-bootstrap'
import BooksitoutProductType from "../type/BooksitoutProductType";
import {BooksitoutCard, BooksitoutCardTitle} from "../BooksitoutCard";

interface Props {
    product: BooksitoutProductType
}

const ProductDetailEtcCard: React.FC<Props> = ({ product }) => {
	return (
		<BooksitoutCard>
			<Card.Body>
				<BooksitoutCardTitle>기타</BooksitoutCardTitle>

				<ul>
					<li>
						<Link href={`/product/${product.name.url}/privacy`}>개인정보처리방침</Link>
					</li>

					<li>
						<Link href={`/portfolio/${product.name.url}`}>개발 관련 정보</Link>
					</li>
				</ul>
			</Card.Body>
		</BooksitoutCard>
	)
}

const Link = styled.a`
	text-decoration: none;
	color: rgb(26, 188, 156);

	&:hover {
		color: rgb(26, 188, 156);
	}
`

export default ProductDetailEtcCard