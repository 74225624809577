import React from "react";
import styled from 'styled-components';
import {ProgressBar} from 'react-bootstrap'
import ColSpacer from './styles/ColSpacer';

interface Props {
    isShowPage?: boolean
}

const BookPageBar: React.FC<Props> = ({isShowPage}) => {
    return (
        <Container>
            <ProgressBarContainer>
                <ProgressBar
                    variant='book'
                    className='mt-3 mb-3'
                    now={0}
                    label={0}
                />
            </ProgressBarContainer>

            {isShowPage && (
                <>
                    <ColSpacer/>
                    <span className='force-1-line'>
                        <b className='text-book'>{`?`}</b> /{' '}{`?`}
                    </span>
                </>
            )}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 400px;
    align-items: center;
    text-align: center;
    justify-content: center;
`;

const ProgressBarContainer = styled.div`
    width: 100%;
`;

export default BookPageBar
