import React from 'react'
import { Card } from 'react-bootstrap'
import BooksitoutProductType from "../type/BooksitoutProductType";
import {BooksitoutCard, BooksitoutCardTitle} from "../BooksitoutCard";

interface Props {
    product: BooksitoutProductType
}

const ProductDetailFeaturesCard: React.FC<Props> = ({ product }) => {
	return (
		<BooksitoutCard>
			<Card.Body>
				<BooksitoutCardTitle>기능</BooksitoutCardTitle>

				<ul>
					{product.features.map((feature) => {
						return <li>{feature}</li>
					})}
				</ul>
			</Card.Body>
		</BooksitoutCard>
	)
}

export default ProductDetailFeaturesCard