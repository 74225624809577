import React from 'react'
import { Card } from 'react-bootstrap'
import parse from 'html-react-parser'
import BooksitoutProductType from "../type/BooksitoutProductType";
import {BooksitoutCard, BooksitoutCardTitle} from "../BooksitoutCard";

interface Props {
    product: BooksitoutProductType
}

const ProductDetailDescriptionCard: React.FC<Props> = ({ product }) => {
	return (
		<BooksitoutCard>
			<Card.Body>
				<BooksitoutCardTitle>소개</BooksitoutCardTitle>

				<p>{parse(product.description.long)}</p>
			</Card.Body>
		</BooksitoutCard>
	)
}

export default ProductDetailDescriptionCard