import { useState, useEffect } from 'react'

type Language = 'ko' | 'ja' | 'en' | 'zh'

const useBrowserLanguage = (): Language | null => {
  const [language, setLanguage] = useState<Language | null>(null)

  useEffect(() => {
    const browserLanguage = navigator.language || navigator.languages[0]

    const langPrefix = browserLanguage.slice(0, 2) as Language

    if (['ko', 'ja', 'en', 'zh'].includes(langPrefix)) {
      setLanguage(langPrefix)
    } else {
      setLanguage(null)
    }
  }, [])

  return language
}

export default useBrowserLanguage