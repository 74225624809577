import styled from 'styled-components';
import {Card} from 'react-bootstrap';

const BooksitoutCard = styled(Card)`
  border: 1px solid rgba(0, 0, 0, 0.125);
`

const BooksitoutCardTitle = styled.h3`
    margin-bottom: 20px;
    justify-content: start;
    text-align: left;
    font-weight: bold;
`

export {BooksitoutCard, BooksitoutCardTitle}