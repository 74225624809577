import React from "react";
import styled from 'styled-components';
import {Modal as BootstrapModal} from 'react-bootstrap'

interface Props {
    titleText: string | null

    isShowing: boolean
    isPreventClose?: boolean
    size?: 'sm' | 'md' | 'lg' | 'xl'

    onClose: () => void
    body: React.ReactNode
}

const Modal: React.FC<Props> = ({isShowing, onClose, titleText, body, isPreventClose, size}) => {
    return (
        <BootstrapModalWrapper
            show={isShowing}
            onHide={onClose}
            centered
            fullscreen='md-down'
            // @ts-ignore
            size={size}

            backdrop={isPreventClose ? 'static' : true}
        >
            {
                titleText !== null && (
                    <Header closeButton>
                        <Title>{titleText}</Title>
                    </Header>
                )
            }

            <BootstrapModal.Body>
                {body}
            </BootstrapModal.Body>
        </BootstrapModalWrapper>
    )
}

const BootstrapModalWrapper = styled(BootstrapModal)`
    z-index: 9999;
`

const Header = styled(BootstrapModal.Header)`
    text-align: center;
`

const Title = styled.h3`
    width: 100%;
    padding-left: 30px;
    margin: 0;
`;

export default Modal