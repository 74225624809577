import BooksitoutProductType from "../../type/BooksitoutProductType";
import BooksitoutProductStatus from "../../type/BooksitoutProductStatus";
import BooksitoutDemoImage from "../demos/BooksitoutDemoImage";

const booksitoutProductFeaturedDays: BooksitoutProductType = {
    id: 0,
    status: BooksitoutProductStatus.PLANNED,
    name: {
        korean: "인생의 날",
        english: "Featured Days",
        url: "featured-days"
    },
    icon: "",
    link: [],
    thumbnailImage: BooksitoutDemoImage.featuredDays,
    detailImage: BooksitoutDemoImage.featuredDays,
    description: {
        short: "기억하고 싶은 인상적인 인생의 날들을 기록하세요",
        medium: "",
        long: ""
    },
    features: [],
    demoImages: [],
    technologyUsed: [],
    challenges: [],
    participants: [
        {
            name: '박진겸',
            url: 'https://github.com/jinkyumpark',
            position: 'Developer'
        }
    ],
    db: {
        images: [],
        explanations: []
    },
    explanations: []
}

export default booksitoutProductFeaturedDays