import React from "react";
import styled from 'styled-components';
import IndexContentContainer from "../../../index/IndexContentContainer"
import BookReadingSessionResponse from "../../reading/BookReadingSessionResponse"
import CardAddButton from '../../../../common/button/CardAddButton';
import ContentContainer from "../../../../common/styles/ContentContainer";
import RowSpacer from "../../../../common/styles/RowSpacer";
import utils from "../../../../common/utils";

interface Props {
    readingSessions: BookReadingSessionResponse[]
}

const BookDetailReadingSessionCard: React.FC<Props> = ({readingSessions}) => {
    return (
        <IndexContentContainer>
            <CardAddButton onClick={() => alert('Hello World')}/>

            <Container>
                {
                    readingSessions.length > 0
                        ?
                        <NonEmptyCase readingSessions={readingSessions}/>
                        :
                        <EmptyCase/>
                }
            </Container>
        </IndexContentContainer>
    )
}

const EmptyCase = () => {
    return (
        <EmptyCaseContainer>
            <EmptyCaseText>📚 기록하신 독서 활동이 없어요!</EmptyCaseText>
        </EmptyCaseContainer>
    )
}

const NonEmptyCase: React.FC<Props> = ({readingSessions}) => {
    return (
        <NonEmptyCaseContainer>
            {
                readingSessions
                    .filter((readingSession) => readingSession.endTime != null)
                    .map((readingSession) => {
                        return (
                            <ReadingSessionContainer key={readingSession.id} height={100}>
                                <ReadingSessionTimeContainer>
                                    {readingSession.readTimeInMinutes ?? '?'}분
                                </ReadingSessionTimeContainer>

                                <ReadingSessionPageContainer>
                                    <Bold>{readingSession.startPage ?? '?'}</Bold>P
                                    - <Bold>{readingSession.endPage ?? '?'}</Bold>P
                                </ReadingSessionPageContainer>


                                <ReadingSessionDateContainer>
                                    {utils.formatTime(readingSession.startTime?.toString() ?? null) ?? '?'}
                                </ReadingSessionDateContainer>

                                <RowSpacer size={10}/>
                            </ReadingSessionContainer>
                        )
                    })
            }
        </NonEmptyCaseContainer>
    )
}

const EmptyCaseContainer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: black;
`;

const EmptyCaseText = styled.h3`
    text-align: center;
`;

const Container = styled.div`
    min-height: 250px;
    width: 100%;
`;

const NonEmptyCaseContainer = styled.div.attrs({
    className: 'row'
})`
`

const ReadingSessionContainer = styled(ContentContainer).attrs({
    className: 'col-12 col-sm-6 col-md-4 col-xl-3'
})`
`

const ReadingSessionDateContainer = styled.div`
    position: absolute;
    right: 15px;
    bottom: 15px;
`

const ReadingSessionTimeContainer = styled.div`
    font-size: 40px;
`

const ReadingSessionPageContainer = styled.div`
    position: absolute;
    right: 15px;
    bottom: 40px;
`

const Bold = styled.span.attrs({
    className: 'text-book'
})`
    font-weight: bold;
`

export default BookDetailReadingSessionCard