import BooksitoutProductType from "../type/BooksitoutProductType";
import BooksitoutProductSubstrack from "./products/BooksitoutProductSubstrack";
import BooksitoutProductPurrfitTracker from "./products/BooksitoutProductPurrfitTracker";
import BooksitoutProductPianoman from "./products/BooksitoutProductPianoman";
import BooksitoutProductBooksitout from "./products/BooksitoutProductBooksitout";
import BooksitoutProductFeaturedDays from "./products/BooksitoutProductFeaturedDays";

const BooksitoutProduct: BooksitoutProductType[] = [
    BooksitoutProductSubstrack,
    BooksitoutProductPurrfitTracker,
    BooksitoutProductPianoman,
    BooksitoutProductBooksitout,
    BooksitoutProductFeaturedDays,
]

export default BooksitoutProduct