import { getDefaultKeyBinding, KeyBindingUtil } from 'draft-js'
const {hasCommandModifier} = KeyBindingUtil;

const TextFormKeyBindingFn = (e: KeyboardEvent): string => {
	// @ts-ignore
	if (e.keyCode === 69 && hasCommandModifier(e)) {
		return 'highlight'
	}

	// @ts-ignore
	return getDefaultKeyBinding(e)
}

export default TextFormKeyBindingFn