import React from 'react'
import styled from 'styled-components';
import BooksitoutProduct from "./data/BooksitoutProduct";
import BooksitoutProductStatus from "./type/BooksitoutProductStatus";
import BooksitoutProductCard from "./BooksitoutProductCard";
import RouteContainer from "../common/styles/RouteContainer";
import RowSpacer from "../common/styles/RowSpacer";

const BooksitoutProductsRoute = () => {
    return (
        <RouteContainer className={'text-center'}>
            <RowSpacer/>

            <RouteTitle>제품</RouteTitle>
            <RouteSubTitle>실제로 서비스되고 있는 제품들</RouteSubTitle>

            <Divider/>

            <Row>
                {BooksitoutProduct
                    .filter((portfolio) => portfolio.status === BooksitoutProductStatus.PRODUCTION)
                    .map((product) => {
                        return (
                            <div className="p-0 mb-4">
                                <BooksitoutProductCard product={product}/>
                            </div>
                        )
                    })}
            </Row>

            <RowSpacer/>
        </RouteContainer>
    )
}

const Divider = styled.div.attrs({
    className: 'mb-5',
})``

const Row = styled.div.attrs({
    className: 'row container ps-0',
})`
    justify-content: center;
    margin: 0;
`

const RouteTitle = styled.h2.attrs({
    className: 'text-start'
})`
`;

const RouteSubTitle = styled.h5.attrs({
    className: 'text-start text-secondary',
})``

export default BooksitoutProductsRoute
