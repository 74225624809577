import styled from 'styled-components';

interface RowSpacerProps {
  size?: number;
}

const RowSpacer = styled.div<RowSpacerProps>`
    padding-top: ${props => props.size}px;
    padding-bottom: ${props => props.size}px;
`;

RowSpacer.defaultProps = {
	size: 20
}  

export default RowSpacer