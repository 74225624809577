import React from 'react'
import {useParams} from 'react-router';
import BooksitoutProductType from "./type/BooksitoutProductType";
import BooksitoutProduct from "./data/BooksitoutProduct";
import NoContent from "../common/NoContent";
import RowSpacer from "../common/styles/RowSpacer";
import ProductDetailBasicInfo from './productDetail/ProductDetailBasicInfo';
import ProductDetailDescriptionCard from './productDetail/ProductDetailDescriptionCard';
import ProductDetailFeaturesCard from './productDetail/ProductDetailFeaturesCard';
import ProductDetailDemoCard from './productDetail/ProductDetailDemoCard';
import ProductDetailEtcCard from './productDetail/ProductDetailEtcCard';
import ProductDetailLanguageCard from "./productDetail/ProductDetailLanguageCard";
import RouteContainer from "../common/styles/RouteContainer";
import useBrowserLanguage from "../common/hooks/useBrowserLanguage";

const BooksitoutProductDetailRoute = () => {
    const {productName} = useParams()
    const {language} = useParams()
    const browserLanguage = useBrowserLanguage()

    const [product, setProduct] = React.useState<BooksitoutProductType | null>(null)
    React.useEffect(() => {
        const productCandidate = BooksitoutProduct.filter((p) => p.name.url.toUpperCase() === productName?.toUpperCase())

        const korean = `소개 | ${productCandidate[0].name.korean ?? '?'}`
        const japanese = `紹介 | ${productCandidate[0].name.english ?? '?'}`
        const english = `Introduction | ${productCandidate[0].name.english ?? '?'}`
        const chinese = `介绍 | ${productCandidate[0].name.english ?? '?'}`

        if (productCandidate.length !== 0) {
            setProduct(productCandidate[0])

            if (language?.toUpperCase() == 'KOREAN') {
                document.title = korean
            } else if (language?.toUpperCase() == 'JAPANESE') {
                document.title = japanese
            } else if (language?.toUpperCase() == 'ENGLISH') {
                document.title = english
            } else {
                if (browserLanguage === 'ko') {
                    document.title = korean
                } else if (browserLanguage === 'ja') {
                    document.title = japanese
                } else if (browserLanguage === 'zh') {
                    document.title = chinese
                } else {
                    document.title = english
                }
            }
        }
    }, [productName])

    if (product === null) {
        return <NoContent message={'해당 제품을 찾을 수 없어요'}/>
    }

    return (
        <RouteContainer>
            <RowSpacer/>

            <ProductDetailBasicInfo product={product}/>
            <RowSpacer/>

            <ProductDetailDescriptionCard product={product}/>
            <RowSpacer/>

            <ProductDetailFeaturesCard product={product}/>
            <RowSpacer/>

            <ProductDetailDemoCard product={product}/>
            <RowSpacer/>

            <ProductDetailEtcCard product={product}/>
            <RowSpacer/>

            <ProductDetailLanguageCard product={product}/>
            <RowSpacer/>
        </RouteContainer>
    )
}

export default BooksitoutProductDetailRoute