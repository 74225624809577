import React, {useCallback} from "react";
import styled from 'styled-components';
import BookResponse from "../BookResponse";
import useReadingSessionStore from './useReadingSessionStore';
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import useCurrentReadingSession from "./useCurrentReadingSession";
import toast from "react-hot-toast";

interface Props {
    book: BookResponse
}

const BookReadingSessionButton: React.FC<Props> = ({book}) => {
    const {start, openTimerModal} = useReadingSessionStore()
    const {currentReadingSession} = useCurrentReadingSession()

    let onClick = useCallback((e: any) => {
        e.preventDefault()

        if (currentReadingSession?.readingSession == null) {
            BooksitoutServer
                .post(`/v1/book/${book.id}/reading-session/start`)
                .then((res) => start(book.id, res.data.id));
        } else if (currentReadingSession.book?.id != book.id) {
            toast.error('이미 진행중인 독서활동이 있어요')
            start(book.id, currentReadingSession.readingSession?.id)
        } else {
            start(book.id, currentReadingSession.readingSession?.id)
        }
    }, [currentReadingSession, book.id, start])

    return (
        <Button onClick={onClick}>
            이어서 읽기
        </Button>
    )
}

const Button = styled.button.attrs({
    className: 'btn btn-book'
})`
    margin-top: 10px;
    width: 100%;

    max-width: 300px;
`;

export default BookReadingSessionButton
