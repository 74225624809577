import {useNavigate} from 'react-router-dom'
import {MdAddCircle as BookIcon} from 'react-icons/md'
import {Button} from 'react-bootstrap'
import styled from 'styled-components';
import breakpoints from '../../config/Breakpoints'
import useLoginStore from '../../routes/login/useLoginStore';

const AddFloatingButton = () => {
    const navigate = useNavigate()

    const isLoggedIn = useLoginStore((state) => state.isLoggedIn())

    const getNavigateUrl = () => {
        const path = window.location.pathname

        const URL_MEMBERSHIP = '/add/membership/image'
        const URL_BOOK = '/add/book/search'

        if (path.startsWith('/library/membership')) {
            return URL_MEMBERSHIP
        }

        if (path === '/add/book/search') {
            return URL_MEMBERSHIP
        }

        return URL_BOOK
    }

    const getIsActive = (): boolean => {
        const path = window.location.pathname
        return path.startsWith('/book/add')
    }

    if (!isLoggedIn) {
        return <></>
    }

    return (
        // @ts-ignore
        <ButtonContainer active={getIsActive()} onClick={() => navigate(getNavigateUrl())}>
            <BookIcon className="h2 p-0 m-0"/>
        </ButtonContainer>
    )
}

const ButtonContainer = styled(Button).attrs({
    variant: "book"
})`
    position: fixed;

    border-radius: 50px;
    z-index: 10;

    width: 60px;
    height: 60px;
    right: 20px;
    bottom: 20px;

    @media screen and (min-width: ${breakpoints.md}) {
        width: 60px;
        height: 60px;
        right: 30px;
        bottom: 30px;
    }

    @media screen and (min-width: ${breakpoints.xl}) {
        right: 60px;
        bottom: 60px;
    }
`

export default AddFloatingButton
