import ApiUrls from "../../../../ApiUrls";
import styled from 'styled-components';
import SearchBar from "../../../search/searchbar/SearchBar";
import {useEffect, useState} from "react";
import {BooksitoutServer} from "../../../../config/BooksitoutServer";
import SearchBookResponse from "../../../search/book/SearchBookResponse";
import toast from "react-hot-toast";
import SearchBookCard from "../../../search/book/SearchBookCard";
import RowSpacer from "../../../../common/styles/RowSpacer";
import AddBookSearchModal from "./AddBookSearchModal";
import breakpoints from "../../../../config/Breakpoints";
import useSearchQuery from "../../../../common/hooks/useSearchQuery";
import SearchBookCardPlaceholder from "../../../search/book/SearchBookCardPlaceholder";

const AddBookSearchRoute = () => {
    const {query, setQuery, dQuery} = useSearchQuery('', 200)
    const [books, setBooks] = useState<SearchBookResponse[]>([])

    const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false)
    const [selectedBook, setSelectedBook] = useState<SearchBookResponse | null>(null)

    useEffect(() => {
        if (dQuery !== '') {
            BooksitoutServer
                .get(ApiUrls.Search.Book.GET_NO_HISTORY(query))
                .then((res) => res.data)
                .then((data: SearchBookResponse[]) => setBooks(data))
                .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요.'))
        }
    }, [dQuery])

    return (
        <div>
            <AddBookSearchModal
                isAddModalOpen={isAddModalOpen}
                onClose={() => setIsAddModalOpen(false)}
                selectedBook={selectedBook}
            />

            <Center>
                <SearchBarContainer>
                    <SearchBar
                        placeholder={"추가할 책을 검색해 주세요"}
                        query={query}
                        queryHistories={[]}
                        querySuggestions={[]}
                        isShowingQueryHistory={false}
                        isShowingAutoComplete={false}
                        isFocusOnAppear={true}
                        setQuery={(q) => setQuery(q)}
                        setIsShowingQueryHistory={(b) => {}}
                        handleSubmit={(e) => e.preventDefault()}
                    />
                </SearchBarContainer>
            </Center>

            <RowSpacer/>

            <Row>
                {
                    (books.length == 0 || query == '')
                        ?

                        (
                            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
                                .map(() => (
                                    <Col>
                                        <SearchBookCardPlaceholder/>
                                        <RowSpacer/>
                                    </Col>
                                ))
                        )

                        :

                        (
                            books
                                .map((book) => (
                                    <Col>
                                        <SearchBookCard
                                            book={book}
                                            onClick={() => {
                                                setIsAddModalOpen(true)
                                                setSelectedBook(book)
                                            }}
                                        />
                                        <RowSpacer/>
                                    </Col>
                                ))
                        )
                }
            </Row>
        </div>
    )
}

const Center = styled.div`
    display: flex;
    justify-content: center;
`

const SearchBarContainer = styled.div`
    width: 50%;

    @media screen and (max-width: ${breakpoints.md}) {
        width: 100%;
    }
`;

const Row = styled.div.attrs({
    className: 'row'
})`
`

const Col = styled.div.attrs({
    className: 'col-6 col-md-3 col-xl-2'
})`
`

export default AddBookSearchRoute