import React, {useState} from 'react'
import styled from 'styled-components';
import Modal from '../../../common/Modal';
import useReadingSessionStore from "./useReadingSessionStore";
import {Button as BootstrapButton, Form} from "react-bootstrap";
import RowSpacer from "../../../common/styles/RowSpacer";
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import toast from 'react-hot-toast';
import {useNavigate} from "react-router-dom";
import NumberInput from "../../../common/form/NumberInput";
import useCurrentReadingSession from './useCurrentReadingSession';
import BookReadingSessionResponse from "./BookReadingSessionResponse";

const BookReadingSessionEndModal = () => {
    const {isEndModalOpen, closeEndModal,} = useReadingSessionStore()

    return (
        <Modal
            titleText={null}
            isShowing={isEndModalOpen}
            onClose={closeEndModal}
            body={<Body/>}
            size={'md'}
        />
    )
}

const Body = () => {
    const navigate = useNavigate()

    const {
        stop, closeEndModal,
        bookId, readingSessionId, timerInSeconds,
        playConfetti
    } = useReadingSessionStore()

    const {currentReadingSession, refresh} = useCurrentReadingSession()

    // @ts-ignore
    const [startPage, setStartPage] = useState<string | null>(currentReadingSession?.book?.currentPage ?? 0)
    const [endPage, setEndPage] = useState<string | null>()

    const handleSave = (e) => {
        e.preventDefault()

        let isLast = false
        // @ts-ignore
        if (endPage != null && endPage >= currentReadingSession?.book?.endPage) {
            isLast = window.confirm('책을 다 읽은 거 같아요. 완료 처리할까요?')
        }

        const body = {
            endTime: new Date(),
            readingTimeInMinutes: timerInSeconds / 60,
            startPage: startPage,
            endPage: endPage,
            isLast: isLast
        }

        BooksitoutServer
            .post(`/v1/book/reading-session/${readingSessionId}/end`, body)
            .then(() => {
                stop()
                navigate(`/book/mine/${bookId}`)

                if (isLast) {
                    playConfetti()
                    toast.success('책을 다 읽었어요! 축하 드려요! 👏')
                } else {
                    toast.success('독서활동을 저장했어요')
                }
            })
            .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요'))
    }

    const handleDelete = () => {
        BooksitoutServer
            .delete(`/v1/book/reading-session/end`)
            .then(() => {
                toast.success('독서활동을 삭제했어요.')
                stop()
                navigate(`/book/mine/${bookId}`)
            })
            .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요'))
    }

    return (
        <Form onSubmit={handleSave}>
            <Title>독서활동 끝내기</Title>
            <RowSpacer size={10}/>

            <Row>
                <Col>
                    <h6>시작 페이지</h6>
                    <NumberInput
                        placeholder={'시작 페이지'}
                        onChange={(e) => setStartPage(e.target.value)}
                        isFocusOnAppear={false}
                        value={startPage}
                    />
                </Col>

                <Col>
                    <h6>끝 페이지</h6>
                    <NumberInput
                        placeholder={'끝 페이지'}
                        onChange={(e) => setEndPage(e.target.value)}
                        isFocusOnAppear={true}
                    />
                </Col>
            </Row>

            <RowSpacer/>

            {/*<Button variant={'book'} className={'w-100'}>읽은 시간으로 추측하기</Button>*/}

            <Row>
                <Col>
                    {/* @ts-ignore */}
                    <Button variant={'book-danger'} onClick={handleDelete}>
                        삭제하기
                    </Button>
                </Col>

                <Col>
                    <Button variant={'book'} type={'submit'}>
                        {endPage == null || endPage == '' ? '페이지 없이 저장하기' : '저장하기'}
                    </Button>
                </Col>

                <Col>
                    <CancelButton onClick={closeEndModal}>취소</CancelButton>
                </Col>
            </Row>
        </Form>
    )
}

const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
`

const Row = styled.div.attrs({
    className: 'row'
})`
    justify-content: center;
`

const Col = styled.div.attrs({
    className: 'col-12 col-md-6 mt-2'
})`
`

const Button = styled(BootstrapButton).attrs({})`
    width: 100%;
`

const CancelButton = styled(Button).attrs({
    variant: 'book-danger',
})`
`

export default BookReadingSessionEndModal
