import {create} from 'zustand'
import BookMemoResponse from "./BookMemoResponse";

interface BookMemoStore {
    memos: BookMemoResponse[]
    init: (memos: BookMemoResponse[]) => void
    addMemo: (memo: BookMemoResponse) => void
    deleteMemo: (memoId: number) => void
}

const useBookMemoStore = create<BookMemoStore>(set => ({
    memos: [],
    init: (memos) => set({memos}),
    addMemo: (memo) => set(state => ({memos: [...state.memos, memo]})),
    deleteMemo: (memoId) => set(state => ({memos: state.memos.filter(memo => memo.id !== memoId)}))
}))

export default useBookMemoStore