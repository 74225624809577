import BooksitoutProductType from "../../type/BooksitoutProductType";
import BooksitoutProductStatus from "../../type/BooksitoutProductStatus";
import BooksitoutWebsiteIcon from "../icons/BooksitoutWebsiteIcon";
import BooksitoutDemoImage from "../demos/BooksitoutDemoImage";

const booksitoutProductPianoman: BooksitoutProductType = {
	id: 5,
	status: BooksitoutProductStatus.STARTED,
	name: {
		korean: '피아노맨',
		english: 'Pianoman',
		url: 'piano-man',
	},
	icon: BooksitoutWebsiteIcon.pianoman,
	link: [
		{
			link: 'https://apps.apple.com/us/app/pianoman/id6456449788',
			icon: BooksitoutWebsiteIcon.appleAppstore,
			border: false,
		},
	],
	thumbnailImage: BooksitoutDemoImage.pianoman,
	detailImage: BooksitoutDemoImage.pianoman,
	description: {
		short: '내 악기 연습을 기록할 수 있는 iOS 앱',
		medium: '',
		long: '',
	},
	features: [],
	demoImages: [],
	technologyUsed: [],
	challenges: [],
	participants: [
		{
			name: '박진겸',
			url: 'https://github.com/jinkyumpark',
			position: 'Developer',
		},
	],

	db: {
		images: [],
		explanations: [],
	},

	explanations: [
		
	]
}

export default booksitoutProductPianoman