import React from 'react'
import styled from 'styled-components';
import {BooksitoutCard, BooksitoutCardTitle} from "../BooksitoutCard";
import {Card} from "react-bootstrap";
import BooksitoutProductType from "../type/BooksitoutProductType";

interface Props {
    product: BooksitoutProductType
}

const ProductDetailLanguageCard: React.FC<Props> = ({product}) => {
    return (
        <BooksitoutCard>
            <Card.Body>
                <BooksitoutCardTitle>다른 언어로 보기</BooksitoutCardTitle>

                <ul>
                    <li>
                        <Link href={`/product/${product.name.url}.english`}>영어</Link>
                    </li>

                    <li>
                        <Link href={`/portfolio/${product.name.url}.japanese`}>일본어</Link>
                    </li>
                </ul>
            </Card.Body>
        </BooksitoutCard>
    )
}

const Link = styled.a`
    text-decoration: none;
    color: rgb(26, 188, 156);

    &:hover {
        color: rgb(26, 188, 156);
    }
`

export default ProductDetailLanguageCard
